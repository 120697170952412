var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"synthesis-projects"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',[_c('h1',{staticClass:"title"},[_vm._v("Projets")]),_c('span',{attrs:{"slot":"actions"},slot:"actions"},[_c('b-select',{attrs:{"placeholder":"Période"},model:{value:(_vm.currentMonth),callback:function ($$v) {_vm.currentMonth=$$v},expression:"currentMonth"}},_vm._l((_vm.months),function(date){return _c('option',{key:date.value,attrs:{"checked":""},domProps:{"value":date.value}},[_vm._v(" "+_vm._s(date.name)+" ")])}),0)],1)]),_c('div',{staticClass:"time"},[_vm._v(" Dernière mise à jour le "+_vm._s(_vm._f("date")(_vm.latestRefresh,"DD/MM/YYYY"))+" à "+_vm._s(_vm._f("date")(_vm.latestRefresh,"HH:mm"))+" ")]),_c('datatable',{ref:"projectList",attrs:{"fetch":_vm.fetchSynthesis,"entity":"sheet","enableDownload":"","downloadEntity":"sheets/synthesis"},scopedSlots:_vm._u([{key:"direction",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Direction","sortable":true,"sortBy":"sheet.project.region.direction.name"}},[_vm._v(" "+_vm._s(item.direction)+" ")])}},{key:"region",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Région","sortable":true,"sortBy":"sheet.project.region.name"}},[_vm._v(" "+_vm._s(item.region)+" ")])}},{key:"project",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Projet","sortable":true,"sortBy":"sheet.project.name"}},[_vm._v(" "+_vm._s(item.projectName)+" ")])}},{key:"sheet",fn:function(ref){
var sheet = ref.item.sheet;
return _c('div',{attrs:{"title":"Fiche Affaire","sortable":true,"sortBy":"sheet.name"}},[_vm._v(" "+_vm._s(sheet && sheet.name)+" ")])}},{key:"owner",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Responsable","sortable":true}},[_vm._v(" "+_vm._s(item.ownerName)+" ")])}},{key:"milestone",fn:function(ref){
var sheet = ref.item.sheet;
return _c('div',{attrs:{"title":"Jalon","sortable":true,"sortBy":"sheet.name"}},[_vm._v(" "+_vm._s(sheet && sheet.milestone)+" ")])}},{key:"imputations",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Imputations"}},_vm._l((item.imputations),function(imputation){return _c('span',{key:'i_' + imputation.eotp,staticClass:"tag is-info is-light"},[_vm._v(_vm._s(imputation.eotp))])}),0)}},{key:"oscarSubProjects",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Sous-projets Oscar"}},_vm._l((item.oscarSubProjects),function(osp){return _c('span',{key:'o_' + osp.id,staticClass:"tag is-info is-light"},[_vm._v(_vm._s(osp.oscarSubProjectName))])}),0)}},{key:"sheetStatus",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Statut","sortable":true,"sortBy":"sheet.sheetStatus.name"}},[_vm._v(" "+_vm._s(item.sheetStatusName)+" ")])}},{key:"administrationStatus",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Statut de gestion","sortable":true,"sortBy":"sheet.administrationStatus.name"}},[_vm._v(" "+_vm._s(item.administrationStatusName)+" ")])}},{key:"startDate",fn:function(ref){
var sheet = ref.item.sheet;
return _c('div',{attrs:{"title":"Date début","sortable":true,"sortBy":"sheet.startDate","type":"date"}},[_vm._v(" "+_vm._s(_vm._f("date")(sheet && sheet.startDate))+" ")])}},{key:"endDate",fn:function(ref){
var sheet = ref.item.sheet;
return _c('div',{attrs:{"title":"Date fin","sortable":true,"sortBy":"sheet.endDate","type":"date"}},[_vm._v(" "+_vm._s(_vm._f("date")(sheet && sheet.endDate))+" ")])}},{key:"referenceBudgetAmount",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Budget référence","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.referenceBudgetAmount))+" ")])}},{key:"amountAps",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Budget APS","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.amountAps))+" ")])}},{key:"amountExe",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Budget EXE","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.amountExe))+" ")])}},{key:"totalPhaseExpenses",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Dépenses au stade","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.totalPhaseExpenses))+" ")])}},{key:"totalVariation",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Ecarts cumulés","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("number")(item.totalVariation))+" ")])}},{key:"totalMonthExpenses",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Dépenses du mois","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.totalMonthExpenses))+" ")])}},{key:"totalMonthVariation",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Ecart du mois","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("number")(item.monthVariation))+" ")])}},{key:"isfOrdered",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"ISF commandé","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.isfOrdered))+" ")])}},{key:"isfDone",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"ISF facturé","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.isfDone))+" ")])}},{key:"rad",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"RAD","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.rad))+" ")])}},{key:"capFnp",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"CAP/FNP","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.capFnp))+" ")])}},{key:"currentTotalAmount",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.currentTotalAmountTitle,"nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("number")(item.currentTotalAmount))+" ")])}},{key:"totalAmount",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Dépenses FDC","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("number")(item.totalAmount))+" ")])}},{key:"previousAmount",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"FDC VS M-1","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("number")((item.totalAmount - item.previousAmount)))+" ")])}},{key:"financialProgress",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-centered",attrs:{"title":"Avancement financier","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("percentage")(item.financialProgress,2))+" ")])}},{key:"realProgress",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-centered",attrs:{"title":"Avancement physique","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("percentage")(item.progress,2))+" ")])}},{key:"realProgressVariation",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-centered",attrs:{"title":"Avancement du mois","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("percentage")(item.realProgressVariation,2))+" ")])}},{key:"referenceBudgetIncomes",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Recettes budget référence","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.referenceBudgetIncomes))+" ")])}},{key:"incomesAps",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Recettes APS","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.incomesAps))+" ")])}},{key:"incomesExe",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Recettes EXE","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.incomesExe))+" ")])}},{key:"incomes",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Reprévisions FDC","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.incomes))+" ")])}},{key:"amountQuoted",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"TS / Devis émis","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.amountQuoted))+" ")])}},{key:"amountOrdered",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"BDC reçus","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.amountOrdered))+" ")])}},{key:"amountInvoiced",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Facturé","sortable":true}},[_vm._v(" "+_vm._s(_vm._f("number")(item.amountInvoiced))+" ")])}},{key:"invoicedLeft",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Reste à facturer","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("number")((item.amountOrdered - item.amountInvoiced)))+" ")])}},{key:"progress",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-centered",attrs:{"title":"Avancement","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("percentage")(item.activity === 1 ? item.progress : item.financialProgress,2))+" ")])}},{key:"activity",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Activité déclarée","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("number")(item.totalDeclaredActivity))+" ")])}},{key:"faePca",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"FAE/PCA","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("number")(item.activity === 1 ? item.progress * item.amountOrdered - item.amountInvoiced : (item.financialProgress * item.amountOrdered - item.amountInvoiced)))+" ")])}},{key:"referenceBudgetMargin",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-centered",attrs:{"title":"% MB budget de référence","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("percentage")(item.referenceBudgetMargin,2))+" ")])}},{key:"apsMargin",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-centered",attrs:{"title":"% MB budget APS","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("percentage")(item.apsMargin,2))+" ")])}},{key:"exeMargin",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-centered",attrs:{"title":"% MB budget EXE","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("percentage")(item.exeMargin,2))+" ")])}},{key:"margin",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-centered",attrs:{"title":"% MB","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("percentage")(item.margin,2))+" ")])}},{key:"comment",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"comment",attrs:{"title":"Commentaire","sortable":true}},[_vm._v(" "+_vm._s(item.comment)+" ")])}},{key:"non-affecte-sap",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Non affecté SAP","nofilter":""}},[_vm._v(" "+_vm._s(item.unaffectedPhaseExpenses)+" ")])}},{key:"non-affecte-isf",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Non affecté ISF","nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("number")((item.isfOrdered - item.affectedIsfOrdered)))+" ")])}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }